import React from 'react';
import pdf from '../../static/documents/AnthemBlueCrossIntake_210601.pdf'

export class Pdf1 extends React.Component {
  componentDidMount() {
    if (typeof window !== `undefined`) {
      window.open(pdf)
    }
  }

  render() {
    return <></>
  }
}

export default Pdf1;
